@-webkit-keyframes swing-out-top-bck {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
  }

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  
.slide-left {
	-webkit-animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.swing-out-top-bck {
	-webkit-animation: swing-out-top-bck 0.2s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	        animation: swing-out-top-bck 0.2s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

.history-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: .5rem;
}

.history-row:hover {
    background: lightgrey;
    cursor: pointer;
}